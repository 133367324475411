<template>
    <div class="content">
        <div class="search">
            <div class="a-inline-block">
                <label for="">姓名</label><a-input size="large"  v-model="name" placeholder="请输入员工姓名搜索" />
            </div>
            <div class="a-inline-block">
                <label for="">角色</label>
                <a-select size="large" v-model="roleId_search" placeholder="">
                    <a-select-option :value="null">全部</a-select-option>
                    <a-select-option v-for="(item,index) in roleData.role" :key="index" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </div>
            <!-- <div class="a-inline-block">
                <label for="">管理部门</label>
                <a-select size="large" v-model="depart" placeholder="">
                    <a-select-option v-for="(item,index) in treeData" :key="index" :value="item.key">
                        {{ item.title }}
                    </a-select-option>
                </a-select>
            </div> -->
            <div class="a-inline-block">
                <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                <a-button size="large" @click="reset">重置</a-button>
            </div>
        </div>
        <div class="">
            <div style="float:left;border:1px solid #ddd;height:auto" v-if="treeData">
                <a-tree :auto-expand-parent="true" @select="onSelect" @expand="onExpand" :tree-data="treeData" style="width: 15%;text-align: left;"/>
            </div>
            
            <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination" style="float:left;width: 83%;margin-left:2%;">
                <span slot="name" slot-scope="text">{{text}}</span>
                <!-- <ww-open-data type="userName" :openid="text"></ww-open-data> -->
                
                
                <img :src="text" alt="" slot="avatar" slot-scope="text" style="width:40px;">
                <a class="action" slot="action" slot-scope="text,record" @click="permission(record.id,record.range)">分配权限</a>
            </a-table>
            <div class="clear"></div>
        </div>
     
        <a-modal v-model="permissionVisible" :title="permissionTitle" @ok="permissionOk">
            <div class="role">
                <!-- <div v-for="(item,index) in roleData.role" :key="index">
                    <a-checkbox v-model="item.isChecked" @click="choose(index,item.id)"></a-checkbox>&nbsp;{{item.name}}
                </div> -->
                <div class="content_row flex">
					<label class="info" for="">请选择角色</label>
					<div class="content_right">
						<a-select size="large" v-model="roleId" placeholder="">
							<a-select-option v-for="(item,index) in roleData.role" :key="index" :value="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</div>
				</div>

                <a-tree v-model="checkedKeys" checkable :tree-data="treeData"/>
            </div>
        </a-modal>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../../../assets/js/request';
export default {
    data() {
        return {
            name: "",
            roleId_search: "",

            data: [],
            columns: [
                { title: '姓名', dataIndex: 'name',scopedSlots: { customRender: 'name' }},
                { title: '头像', dataIndex: 'avatar',scopedSlots: { customRender: 'avatar' }},
                { title: '性别', dataIndex: 'gender'},
                { title: '角色', dataIndex: 'role_name'},
                // { title: '所属部门', dataIndex: 'department_name', width: 500},
                { title: '管理部门', dataIndex: 'range_name', width: 500},
                
                {
                    title: '操作',
                    key: 'operation',
                    width: 150,
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
            // 成员id
            id: "",

            roleData: [],
            // 角色id
            roleId: "",
            permissionVisible: false,
            permissionTitle: "分配权限",
            // 选中的部门
            checkedKeys: [],
            treeData : [],
            // 部门id
            department_id: ""
            // appId: "",
            // timestamp: "",
            // nonceStr: "",
            // signature: "",
            // agentid: "",
        };
    },
    mounted: function(){
        this.getList();
        // 获取角色和部门
        this.getRole();
        this.getDepartment();
    },
    methods:{
        // 员工列表
        getList(){
            requestXml("/scrm/Staff/getList","POST",(res) => {
                for (let i = 0; i < res.list.length; i++) {
                    if(res.list[i].gender == 1){
                        res.list[i].gender = "男"
                    }else if(res.list[i].gender == 2){
                        res.list[i].gender = "女"
                    }else{
                        res.list[i].gender = "未定义"
                    }
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;
            },{"name": this.name,"roleid": this.roleId_search,"department_id":this.department_id,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        reset(){
            this.name = "";
            this.roleId_search = "";
            this.getList();
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getList(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getList(); //向后端发送请求
        },
        // 搜索
        onSearch(){
            this.getList();
        },
        // 获取角色
        getRole(){
            requestXml("/scrm/Authority/getRoleList","GET",(res) => {
                this.roleData = res;
                this.roleId = this.roleData.role[0].id;
            })
        },
        // 获取部门
        getDepartment(){
            requestXml("/scrm/Index/getDepartment","GET",(res) => {
                this.treeData = res;
            })
        },
        // 点击分配权限
        permission(id,range){
            this.checkedKeys = [];
            this.id = id;
            if(range){
                let rangeArr = range.split(",").map(Number);
                this.checkedKeys = rangeArr;
            }
            this.permissionVisible = true;
        },
        // 设置权限
        permissionOk(){
            console.log(this.checkedKeys);
            requestXml("/scrm/Staff/setAuthority","POST",(res) => {
                if(res == "success"){
                    this.roleId = this.roleData.role[0].id;
                    this.checkedKeys = [];
                    this.permissionVisible = false;
                    this.getList();
                }
            },{"id": this.id,"roleid": this.roleId,"range": this.checkedKeys})
        },

        onSelect(keys) {
            console.log(keys);
            this.name = "";
            this.roleId_search = "";
            this.department_id = keys[0];
            this.getList();
        },
        onExpand(keys) {
            console.log('Trigger Expand',keys);
        },
    },
    computed: {
        
        // rowSelection() {
        //     return {
        //         onChange: (selectedRowKeys, selectedRows) => {
        //             console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //         },
        //         getCheckboxProps: record => ({
        //             props: {
        //                 disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //                 name: record.name,
        //             },
        //         }),
        //     };
        // },
    },
};
</script>
<style scoped>
    .content_row{
		margin: 15px 0 30px;
		/* font-size: 16px; */
		align-items: center;
	}
	.content_row .info{
		width: 90px;
		padding-right: 10px;
	}
	
	/* input ,selsct */
	.content_row .ant-input,.content_row .ant-select{
		width: 380px;
	}
    /* .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
        background-color: #36A3ff;
    } */
</style>
